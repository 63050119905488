body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
header h1 {
  font-size: 12px;
  margin: 0;
  font-weight: 600;
  font-family: sans-serif;
}

header {
  display: flex;
  align-items: center;
  padding: 12px;
  justify-content: space-between;
}
.search-container {
  padding: 0px 11px;
  position: relative;
}
button.search-button {
  position: absolute;
  right: 17px;
  border: none;
  background: transparent;
}
.search-container input {
  width: 100%;
  border-radius: 10px;
  border: 1px solid #00000038;
  padding: 2px 8px;
}
header a {
  font-size: 20px;
}

/* slick slider */
